<template>
	<v-card class="mb-2">
		<v-container class="pa-0 ma-0 white--text primary">
			<v-row class="px-4 pb-0 mt-auto">
				<v-col class="subtitle-1">Rubrik</v-col>
				<v-col class="text-right">Ärendenr: {{ issue.number }}</v-col>
			</v-row>

			<v-card-title class="pt-0 white--text">{{ issue.subject }}</v-card-title>

			<v-card-text class="pb-0 white--text">
				<div class="subtitle-1">
					Uppdaterad:
					{{ modifiedDate }} {{ modifiedTime }}
				</div>
			</v-card-text>
			<v-card-text class="subtitle-1 white--text pt-0">
				Status: {{ issue.status.text }}
			</v-card-text>
		</v-container>

		<v-container class="pa-0 ma-0">
			<v-list>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-subtitle>Meddelande</v-list-item-subtitle>
						<p class="ma-0 text-block">{{ issue.content }}</p>
					</v-list-item-content>
				</v-list-item>

				<v-list-item class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Typ</v-list-item-subtitle>
						<v-list-item-title>{{ issue.type.name }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Skapad</v-list-item-subtitle>
						<v-list-item-title>
							{{ createdDate }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-container>
	</v-card>
</template>

<script>
export default {
	props: {
		issue: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		expand: false,
	}),
	computed: {
		modifiedDate() {
			return this.issue.modified ? this.issue.modified.date : '';
		},
		modifiedTime() {
			return this.issue.modified ? this.issue.modified.time : '';
		},
		createdDate() {
			return this.issue.created ? this.issue.created.date : '';
		},
		createdTime() {
			return this.issue.created ? this.issue.created.time : '';
		},
	},
};
</script>

<style>
.text-block {
	white-space: pre-line;
}
</style>
