<template>
	<v-card v-if="comments.length > 0">
		<v-list>
			<v-list-item
				v-for="comment in comments"
				:key="comment.id"
				:class="comment.createdByUser ? 'own-comment' : ''"
			>
				<v-list-item-content>
					<v-list-item-subtitle>
						{{ comment.createdAt.date }} -
						{{ comment.createdAt.time }}
					</v-list-item-subtitle>
					<p class="pb-0 text-block">{{ comment.content }}</p>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
export default {
	props: {
		comments: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style>
.text-block {
	white-space: pre-line;
}
.own-comment {
	text-align: right;
}
</style>
