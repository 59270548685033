<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query
			:query="query"
			:variables="{ id }"
			:update="data => data.issue"
		>
			<template #default="{ result: { loading, data } }">
				<div v-if="data">
					<a-issue-card :issue="data" />

					<v-subheader class="title">
						<template v-if="data.comments.length > 0">
							Kommentarer
						</template>
						<template v-else>
							Inga kommentarer
						</template>
					</v-subheader>

					<a-issue-comments-card :comments="data.comments" class="my-4" />

					<a-issue-add-comment-card :issue-id="data.id" />
				</div>

				<a-loading
					v-else
					:loading="loading"
					type="card-heading, list-item@3, divider, list-item-three-line, list-item"
				/>
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import ALoading from '@/components/Loading';
import AIssueCard from '@/components/IssueCard';
import AIssueCommentsCard from '@/components/IssueCommentsCard';
import AIssueAddCommentCard from '@/components/IssueAddCommentCard';
import { ISSUE, UPDATE_LAST_ACCESSED_TIME } from '@/graphql';

export default {
	components: {
		ALoading,
		AIssueCard,
		AIssueCommentsCard,
		AIssueAddCommentCard,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		query: ISSUE,
		accessedQuery: UPDATE_LAST_ACCESSED_TIME,
	}),
	mounted() {
		this.accessed();
	},
	methods: {
		async accessed() {
			await this.$apollo.mutate({
				mutation: this.accessedQuery,
				variables: { input: { id: this.id } },
			});
			this.$root.$emit('updated_lastAccessedTime');
		},
	},
	metaInfo: {
		title: 'Meddelanden',
	},
};
</script>

<style></style>
