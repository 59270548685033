<template>
	<v-card light>
		<v-form @submit="submit">
			<v-card-text>
				<v-textarea
					v-model="content"
					:error-messages="contentErrors"
					:counter="content.length > 50 ? maxLength : undefined"
					label="Svara"
					placeholder="Skriv en kommentar..."
				/>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn
					@click="submit"
					:loading="loading"
					:disabled="loading"
					color="secondary"
					text
				>
					Skicka
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { ADD_COMMENT, ISSUE } from '@/graphql';

export default {
	props: {
		issueId: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		content: '',
		titleContent: '',
		loading: false,
		minLength: 10,
		maxLength: 400,
	}),
	computed: {
		contentErrors() {
			const errors = [];
			if (!this.$v.content.$dirty) return errors;
			if (!this.$v.content.minLength || !this.$v.content.required) {
				errors.push(
					`Kommentaren måste vara längre än ${this.minLength} tecken`,
				);
			}

			if (!this.$v.content.maxLength) {
				errors.push(
					`Kommentaren måste vara kortare än ${this.maxLength} tecken`,
				);
			}

			return errors;
		},
	},
	methods: {
		resetForm() {
			this.content = '';
			this.$v.$reset();
		},
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			this.addComment();
		},
		addComment() {
			if (!this.$v.$invalid) {
				const input = {
					content: this.content,
					issueId: this.issueId,
				};
				this.loading = true;

				this.$apollo
					.mutate({
						mutation: ADD_COMMENT,
						variables: { input },
						update: (store, { data }) => {
							store.writeQuery({
								query: ISSUE,
								data: {
									issue: data.addComment,
								},
								variables: { id: data.addComment.id },
							});
						},
					})
					.then(() => {
						this.loading = false;
						this.resetForm();
						this.$emit('close');
						this.$toast.success('Kommentaren skapades');
					})
					.catch(error => {
						console.error(error);
						this.loading = false;
						this.$toast.error('Kommentaren kunde inte skapas');
						throw error;
					});
			}
		},
	},
	validations: {
		content: {
			required,
			minLength(value) {
				return minLength(this.minLength)(value);
			},
			maxLength(value) {
				return maxLength(this.maxLength)(value);
			},
		},
	},
};
</script>

<style>
.text-block {
	white-space: pre-line;
}
</style>
